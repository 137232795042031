import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  getDataFromLocalStorage,
  getStringFromLocalStorage,
} from "./localStore";

type Item = {
  deal_code?: string;
  service_method?: { [key: string]: boolean };
  is_sold: boolean;
  stores?: { store_code: string }[];
  week_days?: { [key: string]: boolean };
};

const useCart = () => {
  const service_method = getStringFromLocalStorage("serviceMethod") as any;
  const cart_slicer = useSelector((state: any) => state.cart);
  const discounted_offer = getDataFromLocalStorage("discounted_offer");
  const store = getDataFromLocalStorage("store");

  const get_total_quantity = cart_slicer.cart?.reduce(
    (accumulator: any, item: any) => accumulator + item?.quantity,
    0
  );

  const available_items =
    cart_slicer.cart?.length === 0
      ? []
      : cart_slicer.cart?.filter((item: any) => {
          return (
            item?.service_method?.[service_method?.toLowerCase()] &&
            item?.is_sold &&
            item?.stores?.some(
              (itemx: any) => itemx?.store_code === store?.store_code
            )
          );
        });

  const get_total_price = useMemo(() => {
    let no_price = 0;
    if (cart_slicer.cart?.length === 0) return no_price.toFixed(2);
    const total = available_items.reduce(
      (sum: any, item: any) => sum + item.price * item.quantity,
      0
    );
    return total.toFixed(2);
    // eslint-disable-next-line
  }, [JSON.stringify(cart_slicer.cart)]);

  const get_discounted_price = available_items
    ?.reduce((total: number, item: any) => {
      let sub_total = 0;

      if (discounted_offer && item?.category_code !== "offers") {
        sub_total =
          Number(item?.price) * item?.quantity -
          (discounted_offer?.deal_items?.[0]?.discount_type === "fixed_price"
            ? Number(discounted_offer?.deal_items?.[0]?.discount_value) *
              item?.quantity
            : discounted_offer?.deal_items?.[0]?.discount_type === "dollar_off"
            ? Number(item?.price) * item?.quantity -
              Number(discounted_offer?.deal_items?.[0]?.discount_value)
            : discounted_offer?.deal_items?.[0]?.discount_type === "percent_off"
            ? ((Number(item?.price) * item?.quantity) / 100) *
              discounted_offer?.deal_items?.[0]?.discount_value
            : 0);
      } else {
        sub_total = item?.price * item?.quantity;
      }

      return total + sub_total;
    }, 0)
    .toFixed(2);

  const get_discounted_deal_actual_price = available_items
    ?.reduce((total: number, item: any) => {
      const sub_total =
        item?.sub_category_code !== "half_half" &&
        item?.category_code !== "offers"
          ? item?.price * item?.quantity
          : 0;
      return total + sub_total;
    }, 0)
    .toFixed(2);

  const get_discounted_deal_price = available_items
    ?.reduce((total: number, item: any) => {
      let sub_total = 0;

      if (
        discounted_offer &&
        item?.category_code !== "offers" &&
        item?.sub_category_code !== "half_half"
      ) {
        sub_total =
          Number(item?.price) * item?.quantity -
          (discounted_offer?.deal_items?.[0]?.discount_type === "fixed_price"
            ? Number(discounted_offer?.deal_items?.[0]?.discount_value) *
              item?.quantity
            : discounted_offer?.deal_items?.[0]?.discount_type === "dollar_off"
            ? Number(item?.price) * item?.quantity -
              Number(discounted_offer?.deal_items?.[0]?.discount_value)
            : discounted_offer?.deal_items?.[0]?.discount_type === "percent_off"
            ? ((Number(item?.price) * item?.quantity) / 100) *
              discounted_offer?.deal_items?.[0]?.discount_value
            : 0);
      } else {
        sub_total = 0;
      }

      return total + sub_total;
    }, 0)
    .toFixed(2);

  const get_product_quantity = (id: number) => {
    return cart_slicer.cart?.find((item: any) => item.id === id)?.quantity || 0;
  };

  const get_unavailable_cart_items = () => {
    let unavailable_items = [] as any;
    cart_slicer.cart?.some((item: any) => {
      if (
        !item?.service_method?.[service_method?.toLowerCase()] ||
        !item?.is_sold ||
        !item?.stores?.some(
          (itemx: any) => itemx?.store_code === store?.store_code
        )
      ) {
        unavailable_items.push(item);
      }
      return false;
    });
    return unavailable_items;
  };

  const product_available = (item: Item): boolean => {
    const is_service_available =
      item.service_method?.[service_method.toLowerCase()] ?? false;
    const is_sold = item.is_sold;
    const is_store_valid =
      item.stores?.some(
        (storeItem) => storeItem.store_code === store?.store_code
      ) ?? false;

    return is_service_available && is_sold && is_store_valid;
  };

  return {
    get_total_quantity,
    get_total_price,
    get_discounted_price,
    get_discounted_deal_actual_price,
    get_discounted_deal_price,
    get_product_quantity,
    get_unavailable_cart_items,
    product_available,
  };
};

export default useCart;
