import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { arraysAreEqual } from "utils/compareArrays";
import {
  getDataFromLocalStorage,
  saveDataToLocalStorage,
  clearDataFromLocalStorage,
} from "utils/localStore";

interface CartState {
  cart: any;
  product_quantity: number;
}

const initialState: CartState = {
  cart: getDataFromLocalStorage("cart") || [],
  product_quantity: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    add_to_cart: (state, action: PayloadAction<any>) => {
      const { id, products } = action.payload;
      const item_index = state.cart?.findIndex((item: any) => item?.id === id);

      if (item_index !== -1) {
        const existing_item = state.cart[item_index];
        existing_item.quantity += 1;
        if (existing_item.products && existing_item.products[0]) {
          existing_item.products[0].quantity += 1;
          existing_item.products[0].price = products[0]?.price;
        }
      } else {
        state.cart = [...state.cart, action.payload];
      }

      saveDataToLocalStorage("cart", state.cart);
    },

    removeFromCart: (state, action) => {
      state.cart = state.cart?.filter(
        (item: any) => item?.id !== action?.payload?.id
      );
      saveDataToLocalStorage("cart", state.cart);
    },

    update_quantity: (state, action) => {
      const { id } = action.payload;
      const item_index = state.cart?.findIndex((item: any) => item?.id === id);

      if (item_index !== -1) {
        const existing_item = state.cart[item_index];

        if (existing_item?.quantity > 1) {
          existing_item.quantity -= 1;
          if (existing_item.products && existing_item.products[0]) {
            existing_item.products[0].quantity -= 1;
          }
        } else {
          state.cart.splice(item_index, 1);
        }

        saveDataToLocalStorage("cart", state.cart);
      }
    },

    // pizza crud for cart
    add_pizza_to_cart: (state, action: PayloadAction<any>) => {
      const existing_item_index = state.cart?.findIndex(
        (item: any) =>
          item?.osg_code === action?.payload?.osg_code &&
          item?.products?.[0]?.toppings?.length ===
            action?.payload?.products?.[0]?.toppings?.length &&
          Number(item?.products?.[0]?.dough_code) ===
            Number(action?.payload?.products?.[0]?.dough_code) &&
          Number(item?.products?.[0]?.edge_code) ===
            Number(action?.payload?.products?.[0]?.edge_code) &&
          arraysAreEqual(
            item?.products?.[0]?.toppings,
            action?.payload?.products?.[0]?.toppings
          ) &&
          item?.products?.[0]?.size_code ===
            action?.payload?.products?.[0]?.size_code
      );

      if (existing_item_index > -1) {
        state.cart[existing_item_index].quantity += action?.payload?.quantity;
        state.cart[existing_item_index].products[0].quantity +=
          action?.payload?.quantity;
      } else {
        state.cart = [...state.cart, action?.payload];
      }
      saveDataToLocalStorage("cart", state.cart);
    },

    update_pizza_cart: (state, action: PayloadAction<any>) => {
      const existing_item = state.cart?.find(
        (item: any) => item?.id === action?.payload?.id
      );

      if (existing_item) {
        const item_index = state.cart.findIndex(
          (item: any) => item?.id === existing_item?.id
        );

        state.cart[item_index] = action?.payload;
      }
      saveDataToLocalStorage("cart", state.cart);
    },

    // half n half cart crud
    // if the toppings and size for both of the pizza is same then will update thaat
    add_half_pizza_to_cart: (state, action: PayloadAction<any>) => {
      const existing_item = state.cart?.findIndex(
        (item: any) =>
          item?.osg_code === action?.payload?.osg_code &&
          item?.products?.[0]?.toppings?.length ===
            action?.payload?.products?.[0]?.toppings?.length &&
          Number(item?.products?.[0]?.dough_code) ===
            Number(action?.payload?.products?.[0]?.dough_code) &&
          Number(item?.products?.[0]?.edge_code) ===
            Number(action?.payload?.products?.[0]?.edge_code) &&
          arraysAreEqual(
            item?.products?.[0]?.toppings,
            action?.payload?.products?.[0]?.toppings
          ) &&
          item?.products?.[1]?.toppings?.length ===
            action?.payload?.products?.[1]?.toppings?.length &&
          Number(item?.products?.[1]?.dough_code) ===
            Number(action?.payload?.products?.[1]?.dough_code) &&
          Number(item?.products?.[1]?.edge_code) ===
            Number(action?.payload?.products?.[1]?.edge_code) &&
          arraysAreEqual(
            item?.products?.[1]?.toppings,
            action?.payload?.products?.[1]?.toppings
          ) &&
          item?.products?.[0]?.size_code ===
            action?.payload?.products?.[0]?.size_code
      );

      if (existing_item > -1) {
        state.cart[existing_item].quantity += action?.payload?.quantity;
        state.cart[existing_item].products[0].quantity +=
          action?.payload?.quantity;
      } else {
        state.cart = [...state.cart, action?.payload];
      }
      saveDataToLocalStorage("cart", state.cart);
    },

    update_half_pizza_cart: (state, action: PayloadAction<any>) => {
      const existing_item = state.cart?.find(
        (item: any) => item?.id === action?.payload?.id
      );
      if (existing_item) {
        const item_index = state.cart?.findIndex(
          (item: any) => item?.id === existing_item?.id
        );
        state.cart[item_index] = action?.payload;
      }
      saveDataToLocalStorage("cart", state.cart);
    },

    //offers_add_to_cart
    add_offer_to_cart: (state, action: PayloadAction<any>) => {
      const existing_item = state.cart?.findIndex(
        (item: any) =>
          item?.deal_code === action?.payload?.deal_code &&
          item?.category_code === "offers"
      );

      if (existing_item > -1) {
        let deal_products_check = [] as any;

        state?.cart?.[existing_item]?.products.forEach((item: any) => {
          action?.payload?.products.map((cartItem: any) => {
            return cartItem?.category_code === "pizza"
              ? item?.osg_code === cartItem?.osg_code &&
                item?.toppings?.length === cartItem?.toppings?.length &&
                Number(item?.dough_code) === Number(cartItem?.dough_code) &&
                Number(item?.edge_code) === Number(cartItem?.edge_code) &&
                arraysAreEqual(item?.toppings, cartItem?.toppings) &&
                item?.size_code === cartItem?.size_code
                ? deal_products_check.push(true)
                : deal_products_check.push(false)
              : JSON.stringify(item) === JSON.stringify(cartItem)
              ? deal_products_check.push(true)
              : deal_products_check.push(false);
          });
        });

        if (
          deal_products_check.filter(Boolean).length ===
          state?.cart?.[existing_item]?.products?.length
        ) {
          state.cart[existing_item].quantity += action?.payload?.quantity;
        } else {
          state.cart = [...state.cart, action?.payload];
        }
      } else {
        state.cart = [...state.cart, action?.payload];
      }

      saveDataToLocalStorage("cart", state.cart);
    },

    update_offer_to_cart: (state, action: PayloadAction<any>) => {
      const existing_item = state.cart?.find(
        (item: any) => item?.id === action?.payload?.id
      );
      if (existing_item) {
        const item_index = state.cart?.findIndex(
          (item: any) => item?.id === existing_item?.id
        );
        state.cart[item_index] = action?.payload;
      }
      saveDataToLocalStorage("cart", state.cart);
    },

    add_offer_quantity: (state, action) => {
      const { id, category_code } = action.payload;
      const existing_item_index = state.cart?.findIndex(
        (item: any) => item?.id === id && item?.category_code === category_code
      );

      if (existing_item_index > -1) {
        state.cart[existing_item_index].quantity += 1;
      } else {
        state.cart = [...state.cart, action.payload];
      }

      saveDataToLocalStorage("cart", state.cart);
    },

    subtract_offer_quantity: (state, action) => {
      const { id, category_code } = action.payload;
      const existing_item_index = state.cart?.findIndex(
        (item: any) => item?.id === id && item?.category_code === category_code
      );

      if (existing_item_index > -1) {
        if (state.cart[existing_item_index].quantity > 1) {
          state.cart[existing_item_index].quantity -= 1;
        } else {
          state.cart.splice(existing_item_index, 1);
        }
        saveDataToLocalStorage("cart", state.cart);
      }
    },

    update_cart_data: (state, action: PayloadAction<any>) => {
      state.cart = action?.payload;
      saveDataToLocalStorage("cart", state.cart);
    },

    clear_cart: (state) => {
      state.cart = [];
      clearDataFromLocalStorage("cart");
    },
  },
});

export const {
  add_to_cart,
  update_quantity,
  removeFromCart,
  add_pizza_to_cart,
  update_pizza_cart,
  clear_cart,
  add_half_pizza_to_cart,
  update_half_pizza_cart,
  add_offer_to_cart,
  update_offer_to_cart,
  add_offer_quantity,
  subtract_offer_quantity,
  update_cart_data,
} = cartSlice.actions;

export default cartSlice.reducer;
