import { useUserSettings } from "hooks/useUserSettings";
import MainLayout from "layout/MainLayout";
import { getStringFromLocalStorage } from "utils/localStore";
import {
  About,
  AddAddress,
  AddressDetails,
  Addressess,
  ChooseStore,
  DeliveryPolicy,
  ForgotPassword,
  HygieneCertificate,
  JoinOurTeam,
  Login,
  OTPVerification,
  PhoneVerify,
  PrivacyPolicy,
  ReturnPolicy,
  ServiceMethod,
  SignUpDetails,
  Stores,
  TermsAndConditions,
  Welcome,
} from "./PageAsync";

interface AuthenticationRoutesProps {
  classes?: any;
}

export function AuthenticationRoutes(props: AuthenticationRoutesProps) {
  const { classes } = props;
  const { isMobile } = useUserSettings();
  const token = getStringFromLocalStorage("token") as any;
  const AuthRoutes = {
    path: "/",
    element: <MainLayout classes={classes} />,
    children: [
      {
        path: "/",
        element: isMobile ? (
          <Login classes={classes} />
        ) : (
          <Welcome classes={classes} />
        ),
      },
      {
        path: "/login",
        element: <Login classes={classes} />,
      },
      {
        path: "/phone-verify",
        element: <PhoneVerify classes={classes} />,
      },
      {
        path: "/service-method",
        element: <ServiceMethod classes={classes} />,
      },
      {
        path: "/otp-verify",
        element: <OTPVerification classes={classes} />,
      },
      {
        path: "/signup-details",
        element: <SignUpDetails classes={classes} />,
      },
      {
        path: "/addresses",
        element: <Addressess classes={classes} />,
      },
      {
        path: "/add-address/:id?",
        element: <AddAddress classes={classes} />,
      },
      {
        path: "/address-details/:id?",
        element: <AddressDetails classes={classes} />,
      },
      {
        path: "/choose-store",
        element: <ChooseStore classes={classes} />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword classes={classes} />,
      },
      {
        path: "/stores",
        element: <Stores classes={classes} />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditions classes={classes} />,
      },
      {
        path: "/delivery-policy",
        element: <DeliveryPolicy classes={classes} />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy classes={classes} />,
      },
      {
        path: "/return-policy",
        element: <ReturnPolicy classes={classes} />,
      },
      {
        path: "/about",
        element: <About classes={classes} />,
      },
      {
        path: "/hygiene-certificate",
        element: <HygieneCertificate classes={classes} />,
      },
      {
        path: "/join-our-team",
        element: <JoinOurTeam classes={classes} />,
      },
      {
        path: "/*",
        element: isMobile ? (
          token ? (
            <ServiceMethod classes={classes} />
          ) : (
            <Login classes={classes} />
          )
        ) : token ? (
          <ServiceMethod classes={classes} />
        ) : (
          <Welcome classes={classes} />
        ),
      },
    ],
  };

  return AuthRoutes;
}
